import React from 'react';
import Steps from 'antd/es/steps';

import MobileStepper from './MobileStepper';

const {Step} = Steps

const Stepper = ({step}) => {
  const stepsDatas = [
    {
      step: 1,
      title: 'About your trip'
    },
    {
      step: 2,
      title: 'More details'
    },
    {
      step: 3,
      title: 'About you'
    }
  ]

  const isMobile = window.innerWidth < 500;

  return isMobile ? <MobileStepper steps={stepsDatas} step={step} /> : (
    <Steps current={step - 1} progressDot={isMobile}>
      {stepsDatas.map((stepObj) => {
        let title =  stepObj.title;

        if (isMobile) {
          title = (step) === stepObj.step ? stepObj.title : ""; 
        }

        return (
          <Step
            key={stepObj.step}
            title={title}
          />
        )
      })}
    </Steps>
  )
}

export default Stepper;