import moment from 'moment';

import { DATE_FORMAT } from '../constants/Months';
import MAPPINGS from '../constants/DataMappings';

const getNumberOfChildren = (state) => {
  if (state.numberOfChildren < 1) {
    return "0";
  }

  // add children ages if any
  let ret = `${state.numberOfChildren}`;
  const ages = state.childrenAges
    .filter((age) => age !== null)
    .map((age) => `${age} years old`)
    .join(', ');

  return `${ret} (${ages})`;
}

export default (state) => {
  let toSend = {};

  // match the mappings
  Object.entries(MAPPINGS).map(([key, value]) => {
    toSend[value] = state[key];
    return toSend;
  });

  // part 1
  toSend.travellingDates = {
    start: state.haveSpecificDates ? state.tripDateFrom : null,
    end: state.haveSpecificDates ? state.tripDateTo : null
  }

  // add the recaptcha code
  toSend.recaptchaCode = state.recaptchaCode;

  toSend.dateOfRequest = moment().format(DATE_FORMAT);
  toSend.minNumberOfTravelers = state.numberOfAdults + state.numberOfChildren;
  toSend.maxNumberOfTravelers = state.numberOfAdults + state.numberOfChildren;

  // part 3
  toSend.name = state.name + " " + state.surname;
  toSend.new = true;

  // handle children and their ages
  toSend.numberOfChildren = getNumberOfChildren(state);
  // compute all defaults
  toSend.status = "New"
  toSend.accountManager = ""
  toSend.paymentMethod = ""
  toSend.liquidityUpdated = ""
  toSend.whyLost = ""
  toSend.freelance = ""
  toSend.category = ""
  toSend.customer = ""

  return toSend;
}