import React from 'react';
import Result from 'antd/es/result';
import Button from 'antd/es/button';
import Alert from 'antd/es/alert';

import requestBuilder from '../helpers/requestBuilder';
import {Post} from '../helpers/Http';

import Container from '../components/Container/container';
import Stepper from '../components/Stepper/stepper';
import Step1 from '../components/Step1/step1';
import Step2 from '../components/Step2/step2';
import Step3 from '../components/Step3/step3';

export default class Webform extends React.Component {
  
  constructor(props) {
    super(props);

    this.defaultState = {
      step: 1,
      // infos on step 2
      haveSpecificDates: false,
      tripDateFrom: null,
      tripDateTo: null,
      numberOfAdults: 1,
      numberOfChildren: 0,
      numberOfDays: 1,
      monthsToTravel: [],
      childrenAges : [],

      // infos on step 1
      partsOfMadagascar: [],
      hotelStandards: [],
      mostlyInterestedIn: [],
      tourDescription: "",
      specialRequests: "",

      // infos on step 3
      name: "",
      surname: "",
      email: "",
      phone: "",
      country: "",

      // globals
      loading: false,
      formSubmitted: false,
      recaptchaCode: null,
      step2Valid: false,
      step3Valid: false,
      formHasError: false,
    }

    this.state = {...this.defaultState};
    this.resetForm = this.resetForm.bind(this);
    this.setValue = this.setValue.bind(this);
    this.saveDatas = this.saveDatas.bind(this);
  }

  resetForm() {
    this.setState({...this.defaultState});
  }

  wrapWithContainer(component) {
    const {formSubmitted, step} = this.state;

    return (
      <Container>
        <a href='https://wau.mg'>
          <img className="logo" src="assets/images/WAU_mg_Logo.png" alt="Logo" />
        </a>
        {
          !formSubmitted ? 
            <Stepper step={step} /> : 
            null
        }
        {component}
      </Container>
    )
  }

  isStep1Valid() {
    if (this.state.haveSpecificDates) {
      if (!this.state.tripDateTo || !this.state.tripDateFrom) {
        return false;
      }
    } else {
      if (!this.state.numberOfDays || !this.state.monthsToTravel) {
        return false;
      }
    }

    if(this.state.numberOfAdults < 1 && this.state.numberOfChildren < 1) {
      return false;
    }

    if (this.state.numberOfChildren > 0) {
      if (this.state.childrenAges.length !== this.state.numberOfChildren || 
        this.state.childrenAges.includes(null)) {
          return false;
        }
    }

    return true;
  }

  isStep3Valid() {
    const {name, email, phone, country} = this.state;

    return !!(name && email && phone && country);
  }

  setValue(key, value) {
    // handle pushing keys
    let objUpdater = {[key] : value};

    if (key.includes("_")) {
      const splittedKey = key.split('_');
      const arrValues = [...this.state[splittedKey[0]]];
      arrValues[splittedKey[1]] = value;
      objUpdater = {[splittedKey[0]] : arrValues}
    }

    this.setState(objUpdater, (newState) => {
        switch(this.state.step) {
          case 2:
            this.setState({ step2Valid : this.isStep1Valid()})
          break;
          case 3:
            this.setState({ step3Valid: this.isStep3Valid() })
          break;  
          default:
            break;          
        }
    })
  }

  routeStepper() {
    const {step} = this.state;
    switch(step) {
      case 1:
        return <Step1 
          {
            ...this.state
          }
          setValue={this.setValue}
        /> 
      case 2:
        return <Step2
          {
          ...this.state
          }
          setValue={this.setValue}
        /> 
      case 3:
        return <Step3
          {
          ...this.state
          }
          setValue={this.setValue}
          save={this.saveDatas}
        /> 
      default:
        return (
          <Result
            status="404"
            title="Step not found"
            subTitle="Sorry, this step does not exist."
          />
        )
    }
  }

  saveDatas() {
    // console.log(requestBuilder(this.state))
    this.setState({loading: true});

    Post(`${process.env.REACT_APP_BACKEND_URL}/api/orders`, {
      values : requestBuilder(this.state),
      status: "new"
    })
      .then(() => {
        this.setState({formSubmitted : true})
      })
      .catch((err) => {
        this.setState({ formHasError : true })
      })
  }

  render() {
    // handle form submitted
    const {formSubmitted, formHasError} = this.state;
    if (formHasError) {
      return this.wrapWithContainer(
        <Result
          status="error"
          title="Submission Failed"
          subTitle="An error has occurred during the submission of this request"
          extra={[
            <Button type="primary" key="console" onClick={this.resetForm}>
              Retry
            </Button>,
          ]}
        />
      )
    }

    if (formSubmitted) {
      return this.wrapWithContainer(
        <Result
          status="success"
          title="Your request has been sent"
          subTitle="We have sent you a confirmation email."
          extra={(
            <Button type="primary" onClick={() => window.location.replace('https://wau.mg')}>Go back to homepage</Button>
          )}
        >
        </Result>
      )
    }

    return this.wrapWithContainer((
      <>
        <Alert 
          type="info" 
          style={{textAlign: 'center', marginTop: '3em'}}
          message="Fill out the form to get tailored proposal"
        />
        {this.routeStepper()}
      </>
    ));
  }
}