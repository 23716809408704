import React, { useEffect } from 'react';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import Radio from 'antd/es/radio';
import DatePicker from 'antd/es/date-picker';
import Select from 'antd/es/select';
import moment from 'moment';

import InputNumber from '../InputNumber/InputNumber';
import MONTHS, { DATE_FORMAT } from '../../constants/Months';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const renderSpecificDateInputs = (getFieldDecorator, props) => (
  <>
    <Form.Item label="From">
      {
        getFieldDecorator('tripDateFrom', {
          initialValue: props.tripDateFrom ? moment(props.tripDateFrom) : null
        })(
          <DatePicker onChange={(momentObj) => props.setValue(
            "tripDateFrom",
            momentObj ? momentObj.format(DATE_FORMAT) : momentObj
          )} />
        )
      }
    </Form.Item>
    <Form.Item label="To">
      {
        getFieldDecorator('tripDateTo', {
          initialValue: props.tripDateTo ? moment(props.tripDateTo) : null
        })(
          <DatePicker onChange={(momentObj) => props.setValue(
            "tripDateTo",
            momentObj ? momentObj.format(DATE_FORMAT) : momentObj
          )} />
        )
      }
    </Form.Item>
  </>
)

const renderNoDefinedDateInputs = (getFieldDecorator, props) => (
  <>
    <Form.Item label="Number of days">
      {
        getFieldDecorator('numberOfDays', {
          initialValue: props.numberOfDays || 0
        })(
          <InputNumber
            min={1}
            max={367}
            onChange={(val) => props.setValue("numberOfDays", val)}
          />
        )
      }
    </Form.Item>
    <Form.Item label="I want to travel in">
      {
        getFieldDecorator('monthsToTravel', {
          initialValue: props.monthsToTravel || []
        })(
          <Select mode="multiple" onChange={(vals) => props.setValue("monthsToTravel", vals)}>
            {
              MONTHS.map((month, id) => (
                <Option key={id} value={month}>{month}</Option>
              ))
            }
          </Select>
        )
      }
    </Form.Item>
  </>
)

const renderChildrenAges = (props) => {
  let render = [];
  const {numberOfChildren, setValue} = props;

  if (numberOfChildren < 1) {
    return null;
  }

  for(var i = 1; i <= numberOfChildren; i++) {
    const k = `childrenAges_${i-1}`;
    render.push(
      <Form.Item 
        label={`Age of child ${i}`}
        key={`child-${i}`}
      >
            <InputNumber
              min={0}
              max={20}
              // eslint-disable-next-line no-loop-func
              onChange={(val) => setValue(k, val)}
            />
      </Form.Item>
    )
  }

  return render;
}

window.hackedSelectOn2 = false;

const Step2 = ({ form, haveSpecificDates, setValue, tripDateFrom, tripDateTo, numberOfAdults, numberOfChildren, monthsToTravel, numberOfDays, step2Valid }) => {
  const { getFieldDecorator } = form;

  useEffect(() => {
    if (!window.hackedSelectOn2) {
      const inputs = document.getElementsByClassName('ant-select-search__field');
      if (inputs && inputs.length) {
        [].forEach.call(inputs, input => {
          input.setAttribute('readOnly', 'readOnly')
        })
        window.hackedSelectOn2 = true;
      }
    }
  })

  return (<div className="inner-container">
    <Form {...formItemLayout} onSubmit={() => setValue("step", 3)}>
      {
        haveSpecificDates ?
          renderSpecificDateInputs(getFieldDecorator, { tripDateTo, tripDateFrom, setValue }) :
          renderNoDefinedDateInputs(getFieldDecorator, { setValue, monthsToTravel, numberOfDays })
      }

      <Form.Item label="I have specific dates">
        {getFieldDecorator('haveSpecificDates', {
          initialValue: haveSpecificDates
        })(
          <Radio.Group onChange={(e) => setValue('haveSpecificDates', e.target.value)}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        )}
      </Form.Item>

      <Form.Item label="Number of adults">
        {
          getFieldDecorator('numberOfAdults', {
            initialValue: numberOfAdults
          })(
            <InputNumber
              min={1}
              max={50}
              onChange={(val) => setValue('numberOfAdults', val)}
            />
          )
        }
      </Form.Item>
      <Form.Item label="Number of children">
        {
          getFieldDecorator('numberOfChildren', {
            initialValue: numberOfChildren
          })(
            <InputNumber
              min={0}
              max={50}
              onChange={(val) => setValue('numberOfChildren', val)}
            />
          )
        }
      </Form.Item>

      {
        renderChildrenAges({numberOfChildren, setValue})
      }

      <div style={{ marginTop: '3em' }} />
      <Button
        type="primary"
        size="large"
        style={{ float: 'left' }}
        onClick={() => setValue("step", 1)}
      >
        Previous
      </Button>

      <Button
        type="primary"
        size="large"
        style={{ float: 'right' }}
        htmlType="submit"
        disabled={!step2Valid}
      >
        Next
      </Button>
    </Form>
  </div>)
}

export default Form.create({ name: "Step2" })(Step2);