// ui state / db state 
// few values not listed here are matching and not need mapping
// or have some extra processing before set and are out of scope
export default {
  // part 1
  haveSpecificDates:"specificDates",
  numberOfDays: "numberOfDays",
  monthsToTravel: "wantsToTravelIn",
  numberOfAdults: "numberOfAdults",
  // numberOfChildren: "numberOfChildren",
  
  // part 2
  partsOfMadagascar: "partsOfMG",
  hotelStandards: "hotelStandards",
  mostlyInterestedIn: "interestedIn",
  tourDescription: "tourDescription",
  specialRequests:  "specialRequest",
  
  // part 3
  phone: "phone",
  email: "email",
  country: "country"
}