import React, {useState} from 'react';
import Input from 'antd/es/input';
import Button from 'antd/es/button';

const InputNumber = ({initialValue, min, max, onChange}) => {
  const [currentNumber, setCurrentNumber] = useState(initialValue);
  const invokeOnChange = (val) => {
    if (typeof onChange === 'function') {
      onChange(val);
    }
  }

  const setValue = (value) => {
    if (value < min) {
      setCurrentNumber(min);
      invokeOnChange(min);
      return true;
    }

    if (value > max) {
      setCurrentNumber(max);
      invokeOnChange(max);
      return true;
    }

    setCurrentNumber(value);
    invokeOnChange(value);
  }

  if (currentNumber === undefined) {
    setCurrentNumber(min);
  }

  if (currentNumber < min) {
    setCurrentNumber(min);
    invokeOnChange(min);
  }
  if (currentNumber > max) {
    setCurrentNumber(max)
    invokeOnChange(max);
  }

  return (
    <div style={{display: 'flex'}}>
      <Input style={{width: '40%', textAlign: 'center', fontSize: '1.5em'}}
        defaultValue={currentNumber}
        value={currentNumber}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={(e) => invokeOnChange(e.target.value)} size="large" />
      <Button style={{ width: '20%' }} icon="caret-up" size="large" onClick={() => setValue(currentNumber +1)} />
      <Button style={{ width: '20%' }} icon="caret-down" size="large" onClick={() => setValue(currentNumber - 1)}/>
    </div>
  )
};

export default InputNumber;