import React from 'react';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import ReCAPTCHA from "react-google-recaptcha";

const {getNames} = require('country-list');
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formIsValid = (hasRecaptcha, fieldsFilled) => {
  if (!fieldsFilled) {
    return false;
  }

  if (!hasRecaptcha) {
    return false;
  }

  return true;
}


const Step3 = ({ save, form, name, phone, country,surname, email ,  setValue, step3Valid, recaptchaCode, loading }) => {
  const { getFieldDecorator } = form;

  return (<div className="inner-container">
    <Form {...formItemLayout}>
      <Form.Item label="Name">
        {getFieldDecorator('name', {
          initialValue: name
        })(
          <Input onBlur={(val1) => setValue("name", val1.target.value)} />
        )}
      </Form.Item>

      <Form.Item label="Surname">
        {
          getFieldDecorator('surname', {
            initialValue: surname
          })(
            <Input onBlur={(val2) => setValue("surname", val2.target.value)}/>
          )
        }
      </Form.Item>
      <Form.Item label="Email">
        {
          getFieldDecorator('email', {
            initialValue : email
          })(
            <Input onBlur={(val3) => setValue("email", val3.target.value)} />
          )
        }
      </Form.Item>
      <Form.Item label="Phone">
        {
          getFieldDecorator('phone', {
            initialValue: phone
          })(
            <Input onBlur={(val4) => setValue("phone", val4.target.value)} />
          )
        }
      </Form.Item>

      <Form.Item label="Country">
        {
          getFieldDecorator('country', {
            initialValue: country
          })(
            <Select onChange={(val) => {setValue("country", val)}}>
              {
                getNames().map((country, id) => (
                  <Option key={`country-${id}`} value={country}>{country}</Option>
                ))
              }
            </Select>
          )
        }
      </Form.Item>
      <Form.Item>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
          onChange={(code) => setValue("recaptchaCode", code)}
        />
      </Form.Item>
      <div style={{ marginTop: '3em' }} />
      <Button
        type="primary"
        size="large"
        style={{ float: 'left' }}
        onClick={() => {setValue("step", 2); setValue("recaptchaCode", null)}}
      >
        Previous
      </Button>

      <Button
        type="primary"
        size="large"
        style={{ float: 'right' }}
        disabled={!formIsValid(recaptchaCode, step3Valid)}
        loading={loading}
        onClick={save}
      >
        Send
      </Button>
    </Form>
  </div>)
}

export default Form.create({ name: "Step3" })(Step3);