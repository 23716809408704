import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Progress from 'antd/es/progress';

const MobileStepper = ({steps, step}) => {

  // calculate progress in percent
  const totalSteps = steps.length;
  const percentDone = Math.round(step * 100 / totalSteps);

  return (
    <div className='mobile-stepper-container' style={{
      backgroundColor: 'rgba(0,0,0,.1)',
      padding: '18px'
    }}>
      <Row type="flex" align="middle">
        <Col span={16}>
          <div style={{textAlign: 'center', fontSize: '1.7em'}}>
            {steps[step-1].title}
            <br/>
            {
              steps[step] ? 
                (<span style={{ fontSize: '0.6em' }}>Next: {steps[step].title}</span>) : 
                null
            }
            
          </div>
        </Col>
        <Col span={8}>
          <Progress 
            percent={percentDone} 
            format={() => `${step} / ${totalSteps}`}
            type="circle"
            width={100}
          />
        </Col>
      </Row>
    </div>
  )
}

export default MobileStepper;