import React from 'react';

import './styles.css';

const Container = ({children}) => (
  <div className="container">
    {children}
  </div>
)

export default Container;