import React, {useEffect} from 'react';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Select from 'antd/es/select'

const {Option} =Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

window.hackedSelectOn1 = false;

const Step1 = ({ form, partsOfMadagascar, hotelStandards, mostlyInterestedIn, setValue, tourDescription, specialRequests }) => {
  const { getFieldDecorator } = form;

  useEffect(() => {
    if (!window.hackedSelectOn1) {
      const inputs = document.getElementsByClassName('ant-select-search__field');
      if (inputs && inputs.length) {
        [].forEach.call(inputs, input => {
          input.setAttribute('readOnly', 'readOnly')
        })
        window.hackedSelectOn1 = true;
      }
    }
  })

  return (<div className="inner-container">
    <Form {...formItemLayout} onSubmit={() => setValue("step", 2)}>
      <Form.Item label="Parts of Madagascar">
        {getFieldDecorator('partsOfMadagascar', {
          initialValue: partsOfMadagascar || []
        })(
          <Select mode="multiple" className='antd-multiple-select' onChange={(vals) => setValue("partsOfMadagascar", vals)}>
            {['North', 'East', 'West', 'South', 'Mainland'].map((part, id) => (
              <Option key={`part-${id}`} value={part}>{part}</Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Hotel standards">
        {getFieldDecorator('hotelStandards', {
          initialValue: hotelStandards || []
        })(
          <Select mode="multiple" onChange={(vals) => setValue("hotelStandards", vals)}>
            {['1 star', '2 stars', '3 stars', '4 stars', '4+', 'Some camping'].map((standard, id) => (
              <Option key={`standard-${id}`} value={standard}>{standard}</Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Mostly interested in">
        {getFieldDecorator('mostlyInterestedIn', {
          initialValue: mostlyInterestedIn || []
        })(
          <Select mode="multiple" onChange={(vals) => setValue("mostlyInterestedIn", vals)}>
            {[
              'Wildlife',
              'Trekking',
              'Bicycling',
              'River Tours',
              'Beach stay',
              'Tour of Madagascar ending up at a nice beach'
            ].map((interest, id) => (
              <Option key={`interest-${id}`} value={interest}>{interest}</Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <Form.Item label="Your expectations">
        {
          getFieldDecorator('tourDescription', {
            initialValue: tourDescription
          })(
            <TextArea
              rows={4}
              onBlur={(val) => setValue("tourDescription", val.target.value)}
            />
          )
        }
      </Form.Item>
      <Form.Item label="Special requests">
        {
          getFieldDecorator('specialRequests', {
            initialValue: specialRequests
          })(
            <TextArea
              rows={4}
              onBlur={(val) => setValue("specialRequests", val.target.value)}
            />
          )
        }
      </Form.Item>

      <div style={{ marginTop: '3em' }} />
      <Button
        type="primary"
        size="large"
        style={{ float: 'right' }}
        htmlType="submit"
      >
        Next
      </Button>
    </Form>
  </div>)
}

export default Form.create({name: "Step1"})(Step1);