import React from 'react';

import Webform from './containers/Webform';
import './App.css';

function App() {
  return (
    <Webform />
  );
}

export default App;
